import { AfterViewInit, Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { TranslateService } from '@ngx-translate/core'
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store'
import { RemoteConfig, fetchAndActivate } from '@angular/fire/remote-config'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { Observable } from 'rxjs'
import { filter, map, take, withLatestFrom } from 'rxjs/operators'
import { StateReset } from 'ngxs-reset-plugin'

import { STATES_TO_RESET_WHEN_CHANGING_ORGANIZATION } from './shared/states'
import { GlobalState } from './shared/states/global.state'
import { environment } from '../environments/environment'
import { Environment } from './core/enums/global/environment'
import {
  AddLanguages,
  Logout,
  ScheduleStatesReset,
  SetCurrentLanguage,
  SetDefaultLanguage,
  SetIsLoadingRemoteConfig
} from './shared/states/global.actions'
import Languages from './core/enums/organization/languages'
import { storeVersion } from './shared/states/storeVersion'
import { Constants } from './constants/constants'
import { SetCurrentPeriod } from './module/dashboard/tabs/events-tab/states/events-tab.actions'
import en from '../assets/i18n/en.json'
import nb from '../assets/i18n/nb.json'
import uk from '../assets/i18n/uk.json'
import { DashboardState } from './module/dashboard/states/dashboard.state'
import { WINDOW } from './core/services/window.service'
import { IconRegistryService } from './core/services/icon-registry.service'
import { CollapseSubGroups, UpdateGroupTree } from './shared/modules/group-selector/states/group-selector.actions'
import { UtilsService } from './core/services'
// Facebook pixel function import
declare let fbq: Function

@Component({
  selector: 'app-root',
  styleUrls: ['./app.style.scss'],
  templateUrl: './app.template.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  @SelectSnapshot(GlobalState.defaultLanguage) defaultLanguage: Languages
  @SelectSnapshot(GlobalState.currentLanguage) currentLanguage: Languages
  @SelectSnapshot(GlobalState.isLoadingRemoteConfig) isLoadingRemoteConfig: boolean
  @SelectSnapshot(GlobalState.availableLanguages) availableLanguages: Languages[]
  @SelectSnapshot(DashboardState.isTopLevelAdmin) isTopLevelAdmin: boolean
  @SelectSnapshot(DashboardState.isDivisionAdmin) isDivisionAdmin: boolean
  @Select(GlobalState.pendingReset) pendingStateReset$: Observable<boolean>

  spinner: boolean = false

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private renderer: Renderer2,
    private translate: TranslateService,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private remoteConfig: RemoteConfig,
    private iconRegistryService: IconRegistryService,
    private store: Store,
    private actions$: Actions,
    private utils: UtilsService
  ) {
    this.translate.setTranslation(Languages.EN, en)
    this.translate.setTranslation(Languages.NB, nb)
    this.translate.setTranslation(Languages.UK, uk)

    const previousStoreVersion = JSON.parse(localStorage.getItem('storeVersion')!)
    if (
      previousStoreVersion &&
      (previousStoreVersion.version !== storeVersion.version ||
        previousStoreVersion.latestUpdate !== storeVersion.latestUpdate)
    ) {
      this.store.dispatch([new Logout()]).subscribe(() => {
        localStorage.setItem('storeVersion', JSON.stringify(storeVersion))
      })
    }

    // Firebase AppCheck Debug mode
    ;(window as any).FIREBASE_APPCHECK_DEBUG_TOKEN =
      environment.name === Environment.Production ? false : environment.appCheckDebugToken
  }

  ngOnInit() {
    ;(window as any).Intercom('boot', {
      app_id: environment.intercomAppId
    })

    this.store.dispatch(new SetIsLoadingRemoteConfig(false))
    if (environment.name === Environment.Development) {
      // Skip loading of RemoteConfig, use the defaultConfig
      console.log('Continue without fetching remote config.')
    } else {
      setTimeout(() => (this.spinner = true), Constants.spinnerDelay)
      this.store.dispatch(new SetIsLoadingRemoteConfig(true))
      console.log('Fetching remote config...')
      fetchAndActivate(this.remoteConfig)
        .then(() => console.log('Remote config updated.'))
        .catch((err) => console.error(err))
        .finally(() => this.store.dispatch(new SetIsLoadingRemoteConfig(false)))
    }

    this.iconRegistryService.registerIcons()

    this.initLanguagesConfig()

    this.subscribeToLanguageChange()

    this.onStatesReset()

    this.resetCurrentPeriod()

    this.checkIsWebkitBrowser()

    this.setUpPageTitles()

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe((navigation: NavigationEnd) => {
        if (navigation.url !== '/' && navigation.url !== '/login') {
          const metaRobots = document.createElement('meta')
          metaRobots.name = 'robots'
          metaRobots.content = 'noindex'
          document.head.appendChild(metaRobots)
        }
      })

    // Overcome caching of GroupSelectorState.
    this.store.dispatch(new UpdateGroupTree(null))
    this.store.dispatch(new CollapseSubGroups())
  }

  ngAfterViewInit() {
    this.setScreenHeightUnits()
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('window:orientationchange', ['$event'])
  updateRelativeScreenHeightUnits() {
    this.setScreenHeightUnits()
  }

  private setScreenHeightUnits(): void {
    this.document.documentElement.style.setProperty('--vh', `${this.window.innerHeight / 100}px`)
  }

  private initLanguagesConfig(): void {
    this.store.dispatch(
      new AddLanguages(
        this.availableLanguages && this.availableLanguages.length
          ? this.availableLanguages
          : environment.availableLanguages
      )
    )
    this.store.dispatch(new SetDefaultLanguage(this.defaultLanguage || environment.defaultLang))
    this.store.dispatch(new SetCurrentLanguage(this.currentLanguage || environment.defaultLang))
    this.updateLangAttribute()
  }

  // TODO: Consider moving this logic to GlobalState
  private onStatesReset(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart && !this.router.navigated),
        withLatestFrom(this.pendingStateReset$),
        map(([event, pendingReset]) => pendingReset)
      )
      .subscribe((pendingReset: boolean) => {
        if (pendingReset) {
          this.store.dispatch([
            new StateReset(...STATES_TO_RESET_WHEN_CHANGING_ORGANIZATION),
            new ScheduleStatesReset(false)
          ])
        }
      })
  }

  private checkIsWebkitBrowser(): void {
    // https://stackoverflow.com/a/70585394/20051807
    if ((this.window as any).GestureEvent || this.window.navigator.userAgent.match(/iP(ad|od|hone)/i)) {
      // Used in webkit-hacks.scss
      this.renderer.addClass(this.document.body, 'webkit')
    }
  }

  private resetCurrentPeriod(): void {
    if (!sessionStorage.getItem('existingSession')) {
      this.store.dispatch(new SetCurrentPeriod({}))
      sessionStorage.setItem('existingSession', JSON.stringify(true))
    }
  }

  private subscribeToLanguageChange(): void {
    this.actions$.pipe(ofActionSuccessful(SetCurrentLanguage)).subscribe(() => {
      this.updateLangAttribute()
      this.setPageTitle()
    })
  }

  private setUpPageTitles(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => this.setPageTitle())
  }

  private setPageTitle(): void {
    const routeTitleKey = this.utils.getDeepestPageTitleTranslationKey(this.route)
    this.titleService.setTitle(routeTitleKey ? this.translate.instant(routeTitleKey) : 'Hoopit')
  }

  private updateLangAttribute(): void {
    this.document.documentElement.lang = this.currentLanguage
  }
}
