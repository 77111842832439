<div
  class="main-block"
  [class.main-block--mobile-details-visible]="mobileView === 'details'"
  (click)="$event.stopPropagation()"
>
  <app-loader *ngIf="loading" class="mobile-loader" [size]="80"></app-loader>

  <div class="inner-wrapper">
    <div [@sidebarAnimation]="!user && !isSmallScreen" class="sidebar">
      <section *ngIf="user" class="sidebar__header">
        <app-expand-x *ngIf="this.listOfPreviouslyOpenedUsers.length > 1 && !loading" class="open-previous-profile">
          <button mat-icon-button (click)="openPreviousProfile()">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </app-expand-x>

        <h6 @nameMobileAnimation [@.disabled]="!isSmallScreen" class="name">{{ user.first_name }}</h6>

        <button class="close-profile-button" mat-icon-button (click)="closeProfile()">
          <mat-icon>close</mat-icon>
        </button>
      </section>

      <!-- TODO: set up two separate animations based on "isSmallScreen" -->
      <!-- [@tabsAnimation]="!isSmallScreen" [@tabsMobileAnimation]="isSmallScreen" -->
      <ul @tabsAnimation *ngIf="user">
        <li
          matRipple
          matRippleColor="#dfdfe750"
          [matRippleDisabled]="!isSmallScreen"
          [class.active]="activeTab === UserProfileTab.Info"
        >
          <button appBlurOnClick (click)="openTab(UserProfileTab.Info)">
            <app-profile-picture [user]="user"></app-profile-picture>
            <span>{{ 'user_profile.profile' | translate }}</span>
            <mat-icon class="mobile-icon">chevron_right</mat-icon>
          </button>
        </li>
        <li
          *ngIf="user.can_send_invitation"
          matRipple
          matRippleColor="#dfdfe750"
          [matRippleDisabled]="!isSmallScreen"
          [class.active]="activeTab === UserProfileTab.Invitation"
        >
          <button appBlurOnClick (click)="openTab(UserProfileTab.Invitation)">
            <mat-icon class="label-icon">how_to_reg</mat-icon>
            <span>{{ 'user_profile.invitation' | translate }}</span>
            <mat-icon class="mobile-icon">chevron_right</mat-icon>
          </button>
        </li>
        <li
          *ngIf="isAdmin"
          matRipple
          matRippleColor="#dfdfe750"
          [matRippleDisabled]="!isSmallScreen"
          [class.active]="activeTab === UserProfileTab.Titles"
        >
          <button appBlurOnClick (click)="openTab(UserProfileTab.Titles)">
            <mat-icon class="label-icon">groups</mat-icon>
            <span>{{ 'user_profile.titles' | translate }}</span>
            <mat-icon class="mobile-icon">chevron_right</mat-icon>
          </button>
        </li>
        <li
          matRipple
          matRippleColor="#dfdfe750"
          [matRippleDisabled]="!isSmallScreen"
          [class.active]="activeTab === UserProfileTab.Relations"
        >
          <button appBlurOnClick (click)="openTab(UserProfileTab.Relations)">
            <mat-icon class="label-icon">supervised_user_circle</mat-icon>
            <span>{{ 'user_profile.relations' | translate }}</span>
            <mat-icon class="mobile-icon">chevron_right</mat-icon>
          </button>
        </li>
        <li
          matRipple
          matRippleColor="#dfdfe750"
          [matRippleDisabled]="!isSmallScreen"
          [class.active]="activeTab === UserProfileTab.Payments"
        >
          <button appBlurOnClick (click)="openTab(UserProfileTab.Payments)">
            <mat-icon class="label-icon">monetization_on</mat-icon>
            <span>{{ 'user_profile.unpaid_payments' | translate }}</span>
            <mat-icon class="mobile-icon">chevron_right</mat-icon>
          </button>
        </li>
        <li
          *ngIf="!isCurrentUser && user.can_message"
          matRipple
          matRippleColor="#dfdfe750"
          [matRippleDisabled]="!isSmallScreen"
          [class.active]="activeTab === UserProfileTab.Chat"
        >
          <button appBlurOnClick (click)="openTab(UserProfileTab.Chat)">
            <mat-icon class="label-icon">chat</mat-icon>
            <span>{{ 'organization.chat' | translate }}</span>
            <mat-icon class="mobile-icon">chevron_right</mat-icon>
          </button>
        </li>
      </ul>
    </div>

    <section class="content" [ngSwitch]="activeTab">
      <app-loader *ngIf="loading" class="desktop-loader" [size]="80"></app-loader>

      <header class="content-header">
        <div class="content-header__title">
          <button mat-icon-button class="back-button" (click)="mobileBack()">
            <mat-icon>arrow_back</mat-icon>
          </button>

          <h6 @tabTitleAnimation [@.disabled]="isSmallScreen" *ngSwitchCase="UserProfileTab.Info">
            {{ 'user_profile.profile' | translate }}
          </h6>
          <h6 @tabTitleAnimation [@.disabled]="isSmallScreen" *ngSwitchCase="UserProfileTab.Invitation">
            {{ 'user_profile.invitation' | translate }}
          </h6>
          <h6 @tabTitleAnimation [@.disabled]="isSmallScreen" *ngSwitchCase="UserProfileTab.Titles">
            {{ 'user_profile.titles' | translate }}
          </h6>
          <h6 @tabTitleAnimation [@.disabled]="isSmallScreen" *ngSwitchCase="UserProfileTab.Relations">
            {{ 'user_profile.relations' | translate }}
          </h6>
          <h6 @tabTitleAnimation [@.disabled]="isSmallScreen" *ngSwitchCase="UserProfileTab.Payments">
            {{ 'user_profile.unpaid_payments' | translate }}
          </h6>
          <h6 @tabTitleAnimation [@.disabled]="isSmallScreen" *ngSwitchCase="UserProfileTab.Chat">
            {{ 'organization.chat' | translate }}
          </h6>
        </div>

        <div class="content-header__buttons">
          <ng-container [ngTemplateOutlet]="headerButtons"></ng-container>
        </div>
      </header>

      <div *ngIf="user" class="content-body">
        <ng-container *ngSwitchCase="UserProfileTab.Info" [ngTemplateOutlet]="generalInfoTab"></ng-container>
        <ng-container *ngSwitchCase="UserProfileTab.Invitation" [ngTemplateOutlet]="invitationTab"></ng-container>
        <ng-container *ngSwitchCase="UserProfileTab.Titles" [ngTemplateOutlet]="titlesTab"></ng-container>
        <ng-container *ngSwitchCase="UserProfileTab.Relations" [ngTemplateOutlet]="relationsTab"></ng-container>
        <ng-container *ngSwitchCase="UserProfileTab.Payments" [ngTemplateOutlet]="paymentsTab"></ng-container>
        <ng-container *ngSwitchCase="UserProfileTab.Chat" [ngTemplateOutlet]="chatTab"></ng-container>
      </div>
    </section>
  </div>
</div>

<ng-template #headerButtons>
  <ng-container [ngSwitch]="mainTabMode">
    <app-expand-y *ngSwitchCase="MainTabMode.Overview" class="action-buttons action-buttons--main">
      <app-expand-x
        *ngIf="
          user && activeTab === UserProfileTab.Info && user.activation_status !== ActivationStatus.WaitingForMembership
        "
      >
        <button mat-icon-button (click)="openEditUserDetails()">
          <mat-icon>edit</mat-icon>
        </button>
      </app-expand-x>

      <button class="close-profile-button" mat-icon-button (click)="closeProfile()">
        <mat-icon>close</mat-icon>
      </button>
    </app-expand-y>

    <app-expand-y *ngSwitchCase="MainTabMode.Edit" class="action-buttons">
      <app-button size="small" [disabled]="userProfileForm.invalid" (click)="saveProfile()">
        {{ 'action.save' | translate }}
      </app-button>
      <app-button
        size="small"
        theme="light"
        class="desktop-action-cancel-button"
        (click)="mainTabMode = MainTabMode.Overview"
      >
        {{ 'action.cancel' | translate }}
      </app-button>
    </app-expand-y>
  </ng-container>
</ng-template>

<ng-template #generalInfoTab [ngSwitch]="mainTabMode">
  <!-- TODO: remove animation when opening details on mobile -->
  <div
    @contentAnimation
    [@.disabled]="isSmallScreen && mobileView === 'menu' && mainTabMode === MainTabMode.Overview"
    *ngSwitchCase="MainTabMode.Overview"
    class="content-container content-container--general-info"
    appScrollShadow
  >
    <div class="container">
      <div class="profile-picture-wrapper">
        <app-profile-picture
          [viewable]="true"
          [user]="user!"
          (profilePictureUpdated)="updateProfilePicture($event)"
        ></app-profile-picture>
        <div>
          <h6>{{ user!.first_name }} {{ user!.last_name }}</h6>

          @if (user!.activation_status === ActivationStatus.Invited && !user!.supervised_by_parents) {
            <p class="receives-emails ns-test extra-small">
              {{ 'user_attributes.receives_notifications_via_email' | translate }}
            </p>
          } @else if (user!.last_seen) {
            <p class="online-status ns-test extra-small">
              {{ 'user_attributes.last_online' | translate }} {{ user!.last_seen | date: DateFormat.shortDate2 }}
            </p>
          }

          <div class="activation-status-wrapper">
            <app-user-activation-status
              [status]="user!.activation_status"
              [supervised]="user!.supervised_by_parents"
              [disableTooltip]="true"
            ></app-user-activation-status>
            @switch (user!.activation_status) {
              @case (ActivationStatus.Active) {
                <p class="ns-text small">{{ 'user_activation_status.active' | translate }}</p>
              }
  
              @case (ActivationStatus.Child) {
                <p class="ns-text small">{{ 'user_activation_status.supervised' | translate }}</p>
              }
  
              @case (ActivationStatus.Invited) {
                @if (user!.supervised_by_parents) {
                  <p class="ns-text small">{{ 'user_activation_status.supervised' | translate }}</p>
                } @else {
                  <p class="ns-text small">{{ 'user_activation_status.not_completed_registration' | translate }}</p>
                }
              }
  
              @case (ActivationStatus.WaitingForMembership) {
                <p class="ns-text small">{{ 'user_activation_status.awaits_membership' | translate }}</p>
              }
            }
          </div>
        </div>
      </div>

      <p class="sub-header ns-subtitle">{{ 'user_profile.main_information' | translate }}</p>

      <p class="label ns-text small">{{ 'user_attributes.phone_number' | translate }}</p>
      <p class="ns-text">
        {{ user!.phone_mobile ? (user!.phone_mobile | phone) : 'user_profile.mobile_number_not_provided' | translate }}
      </p>

      <p class="label ns-text small">{{ 'user_attributes.email' | translate }}</p>
      <p class="ns-text">
        @if (user!.email) {
          <a [attr.href]="'mailto:' + user!.email" class="user-email"> {{ user!.email }} </a>
        } @else {
          {{ 'user_profile.email_not_provided' | translate }}
        }
      </p>

      <p class="label ns-text small">{{ 'user_attributes.birth_date' | translate }}</p>
      <p class="ns-text">
        @if (user!.date_of_birth) {
          {{ user!.date_of_birth | date: DateFormat.shortDate2 }}
        } @else {
          {{ 'user_profile.dob_not_provided' | translate }}
        }
      </p>

      <p class="label ns-text small">{{ 'user_attributes.gender' | translate }}</p>
      <p class="ns-text">
        @switch (user!.gender) {
          @case (Gender.Male) {
            {{ 'gender.male' | translate }}
          }
          @case (Gender.Female) {
            {{ 'gender.female' | translate }}
          }
          @default {
            {{ 'user_profile.gender_not_provided' | translate }}
          }
        }
      </p>

      @if (isCurrentUser) {
        <p class="label ns-text small">{{ 'user_attributes.nationality' | translate }}</p>
        <p class="ns-text">{{ user!.nationality.name }}</p>
      }

      <p class="label ns-text small">{{ 'user_attributes.address' | translate }}</p>
      @if (!user!.street_address && !user!.postal_code && !user!.city) {
        <p class="ns-text">{{ 'user_profile.address_not_provided' | translate }}</p>
      } @else {
        <p class="ns-text">
          {{ user!.street_address }}<span *ngIf="user!.street_address && user!.postal_code">, </span>
          {{ user!.postal_code }} {{ user!.city }}
        </p>
      }

      @if (user!.media_privacy_permission) {
        <p class="label ns-text small">{{ 'user_attributes.photo_permission' | translate }}</p>
        @if (isCurrentUser) {
          @switch (user!.media_privacy_permission) {
            @case (MediaPrivacyPermission.Internal) {
              <p class="ns-text">{{ 'user_attributes.permission_internal_text' | translate }}</p>
            }
            @case (MediaPrivacyPermission.Public) {
              <p class="ns-text">{{ 'user_attributes.permission_public_text' | translate }}</p>
            }
            @case (MediaPrivacyPermission.NoPermission) {
              <p class="ns-text">{{ 'user_attributes.no_permission' | translate }}</p>
            }
            @case (MediaPrivacyPermission.NotSet) {
              <p class="ns-text">
                {{ 'user_attributes.permission_internal_text' | translate }}
                ({{ 'organization.not_set' | translate | lowercase }})
              </p>
            }
          }
        } @else {
          @switch (user!.media_privacy_permission) {
            @case (MediaPrivacyPermission.Internal) {
              <p class="ns-text">{{ 'user_attributes.permission_internal_label' | translate }}</p>
            }
            @case (MediaPrivacyPermission.Public) {
              <p class="ns-text">{{ 'user_attributes.permission_public_label' | translate }}</p>
            }
            @case (MediaPrivacyPermission.NoPermission) {
              <p class="ns-text">{{ 'user_attributes.no_permission_label' | translate }}</p>
            }
            @case (MediaPrivacyPermission.NotSet) {
              <p class="ns-text">
                {{ 'user_attributes.no_permission_label' | translate }}
                ({{ 'organization.not_set' | translate | lowercase }})
              </p>
            }
          }
        }
      }

      <p *ngIf="isPlayer || isGuardian" class="label ns-text small">{{ 'user_profile.roles' | translate }}</p>
      <ul *ngIf="isPlayer" class="user-groups">
        <li *ngFor="let group of user!.groups!.player" class="ns-text player-in-group">
          <span class="text-wrapper">
            {{ 'user_profile.practicing_in' | translate }} {{ group.name }} <span *ngIf="group.division_name">·</span>
            {{ group.division_name }}
          </span>
          <button class="shirt-button" (click)="openJerseyNumberPopup(group)">
            <app-jersey-number>{{ group.jersey_number }}</app-jersey-number>
          </button>
        </li>
      </ul>

      <ul *ngIf="isGuardian" class="user-groups">
        <li *ngFor="let group of user!.groups!.guardian" class="ns-text">
          {{ 'user_profile.guardian_in' | translate }} {{ group.name }}
          <span *ngIf="group.division_name">·</span>
          {{ group.division_name }}
        </li>
      </ul>

      @if (userTitles?.length) {
        <p class="label ns-text small">{{ 'user_profile.titles' | translate }}</p>
        <ul class="user-groups">
          <li *ngFor="let title of userTitles" class="ns-text">
            {{ title.title }} {{ 'user_profile.in' | translate }} {{ title.group }}
            <span *ngIf="title.divisionName">·</span>
            {{ title.divisionName }}
          </li>
        </ul>
      }
    </div>

    @if (customFields.length) {
      <div class="divider"></div>

      <div class="container">
        <p class="sub-header ns-subtitle">{{ 'user_profile.additional_information' | translate }}</p>

        <ul class="custom-fields">
          @for (iterator of customFieldsGrouped | keyvalue; track $index) {
            <p class="group-name ns-subtitle small">
              {{ iterator.value.groupName }}

              @if (iterator.value.parentGroupName) {
                ({{ iterator.value.parentGroupName }})
              }
            </p>

            @for (field of iterator.value.customFields; track $index) {
              <li>
                <p class="label ns-text small">{{ field.name }}</p>
                <p class="ns-text">{{ field.field_data.value || '-' }}</p>
              </li>
            }
          }
        </ul>
      </div>
    }
  </div>

  <form
    @contentAnimation
    *ngSwitchCase="MainTabMode.Edit"
    [formGroup]="userProfileForm"
    class="content-container content-container--edit ns-form ns-light"
    appScrollShadow
  >
    <div class="container">
      <div *ngIf="isCurrentUser" class="profile-picture-wrapper">
        <app-profile-picture
          [user]="user!"
          [updatable]="isCurrentUser"
          [deletable]="true"
          (profilePictureUpdated)="updateProfilePicture($event)"
        ></app-profile-picture>
      </div>

      <div class="ns-inline-fields ns-form-row">
        <mat-form-field class="ns-width-50">
          <mat-label>{{ 'user_attributes.first_name' | translate }}</mat-label>
          <input matInput formControlName="firstName" />
          <mat-icon *ngIf="userProfileForm.controls.firstName.invalid" matSuffix>error_outline</mat-icon>
        </mat-form-field>

        <mat-form-field class="ns-width-50">
          <mat-label>{{ 'user_attributes.last_name' | translate }}</mat-label>
          <input matInput formControlName="lastName" />
          <mat-icon *ngIf="userProfileForm.controls.lastName.invalid" matSuffix>error_outline</mat-icon>
        </mat-form-field>
      </div>

      <mat-form-field>
        <mat-label>{{ 'user_attributes.birth_date' | translate }}</mat-label>
        <input matInput type="date" formControlName="dateOfBirth" [max]="$any(today)" />
        <mat-icon matPrefix>calendar_today</mat-icon>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'user_attributes.gender' | translate }}</mat-label>
        <mat-select formControlName="gender">
          <mat-option [value]="Gender.Male">{{ 'gender.male' | translate }}</mat-option>
          <mat-option [value]="Gender.Female">{{ 'gender.female' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="divider"></div>

    <div class="container">
      <p class="form-sub-header ns-subtitle">{{ 'organization.contact_info' | translate }}</p>

      <app-phone-number
        [phoneCode]="userProfileForm.value.phoneCountryCode!"
        [phoneNumber]="userProfileForm.value.phoneMobile!"
        [required]="contactInfoRequired && isCurrentUser"
        (phoneNumberInput)="handlePhoneNumberUpdate($event)"
      ></app-phone-number>

      <mat-form-field>
        <mat-label>{{ 'user_attributes.email' | translate }}</mat-label>
        <input matInput formControlName="email" />
        <mat-icon *ngIf="userProfileForm.controls.email.invalid" matSuffix>error_outline</mat-icon>
      </mat-form-field>
    </div>

    <ng-container *ngIf="isCurrentUser">
      <div class="divider"></div>

      <div class="container">
        <p class="form-sub-header ns-subtitle">{{ 'user_attributes.address' | translate }}</p>

        <div class="ns-inline-fields ns-form-row">
          <mat-form-field class="ns-width-50">
            <mat-label>{{ 'user_attributes.postal_code' | translate }}</mat-label>
            <input matInput formControlName="postalCode" />
          </mat-form-field>
          <mat-form-field class="ns-width-50">
            <mat-label>{{ 'user_attributes.city' | translate }}</mat-label>
            <input matInput formControlName="city" />
          </mat-form-field>
        </div>
        <mat-form-field class="ns-address">
          <mat-label>{{ 'user_attributes.address' | translate }}</mat-label>
          <input matInput formControlName="streetAddress" />
        </mat-form-field>
      </div>
    </ng-container>

    <div class="divider"></div>

    <div class="container">
      <p class="sub-header ns-subtitle">{{ 'user_profile.permissions' | translate }}</p>
      <div class="photo-permission-description">
        <p *ngIf="isCurrentUser" class="ns-text small">{{ 'user_attributes.permissions_text' | translate }}</p>
        <p *ngIf="!isCurrentUser" class="ns-text small">
          {{ 'user_attributes.permissions_text_2' | translate: { name: user!.first_name + ' ' + user!.last_name } }}
        </p>
        <p class="ns-text small">{{ 'user_attributes.permissions_description' | translate }}</p>
      </div>

      <mat-form-field>
        <mat-label>{{ 'user_attributes.photo_permission' | translate }}</mat-label>
        <mat-select formControlName="mediaPrivacyPermission">
          <ng-container *ngIf="isCurrentUser">
            <mat-option value="no_permission">{{ 'user_attributes.no_permission' | translate }}</mat-option>
            <mat-option value="internal">{{ 'user_attributes.permission_internal_text' | translate }}</mat-option>
            <mat-option value="public">{{ 'user_attributes.permission_public_text' | translate }}</mat-option>
          </ng-container>
          <ng-container *ngIf="!isCurrentUser">
            <mat-option value="no_permission">{{ 'user_attributes.no_permission_label' | translate }}</mat-option>
            <mat-option value="internal">{{ 'user_attributes.permission_internal_label' | translate }}</mat-option>
            <mat-option value="public">{{ 'user_attributes.permission_public_label' | translate }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    @if (customFields.length) {
      <div class="divider"></div>

      <p class="form-sub-header ns-subtitle">{{ 'user_profile.additional_information' | translate }}</p>

      <div class="custom-fields container" formGroupName="customFields">
        @for (iterator of customFieldsGrouped | keyvalue; track $index) {
          <p class="group-name ns-subtitle small">
            {{ iterator.value.groupName }}

            @if (iterator.value.parentGroupName) {
              ({{ iterator.value.parentGroupName }})
            }
          </p>

          @for (field of iterator.value.customFields; track $index) {
            @switch (field.type) {
              @case ('text') {
                <mat-form-field>
                  <mat-label>{{ field.name }}</mat-label>
                  <input matInput [formControlName]="field.id" />
                </mat-form-field>
              }
              @case ('integer') {
                <mat-form-field>
                  <mat-label>{{ field.name }}</mat-label>
                  <input type="number" matInput [formControlName]="field.id" />
                </mat-form-field>
              }
              @case ('date') {
                <mat-form-field>
                  <mat-label>{{ field.name }}</mat-label>
                  <input type="date" matInput [formControlName]="field.id" />
                </mat-form-field>
              }
              @case ('time') {
                <mat-form-field>
                  <mat-label>{{ field.name }}</mat-label>
                  <input type="time" matInput [formControlName]="field.id" />
                </mat-form-field>
              }
              @case ('datetime') {
                <mat-form-field>
                  <mat-label>{{ field.name }}</mat-label>
                  <input type="datetime" matInput [formControlName]="field.id" />
                </mat-form-field>
              }
              @case ('boolean') {
                <div class="ns-checkbox-field">
                  <mat-checkbox [formControlName]="field.id">
                    <div class="ns-label-header">
                      <span class="bold">{{ field.name }}</span>
                    </div>
                  </mat-checkbox>
                </div>
              }
            }
          }
        }
      </div>
    }
  </form>
</ng-template>

<ng-template #invitationTab>
  <div
    @contentAnimation
    [@.disabled]="isSmallScreen"
    class="content-container content-container--resend-invitation"
    appScrollShadow
  >
    <form [formGroup]="resendInvitationForm" class="ns-form ns-light container">
      <div class="description ns-text small">
        <p>
          {{
            'user_profile.resend_invitation_description'
              | translate: { name: user!.first_name + ' ' + user!.last_name, organization: organization.name }
          }}
        </p>
        <p>{{ 'user_profile.resend_invitation_description_2' | translate: { name: user!.first_name } }}</p>
        <p>
          {{
            'user_profile.resend_invitation_reminder'
              | translate
                : {
                    datetime: lastInvitationDatetime ? (lastInvitationDatetime | date: DateFormat.shortDateTime) : '...'
                  }
          }}
        </p>
      </div>

      <app-info-block type="info" [title]="'user_profile.resend_invitation_by_sms_title' | translate">
        <p
          [innerHTML]="
            'user_profile.resend_invitation_by_sms_description'
              | translate: { name: user!.first_name, number: user!.auth_phone_number, sender: smsSender }
          "
        ></p>
      </app-info-block>

      <div class="cost ns-text small">
        <span>{{ 'sms.cost' | translate }}:</span>
        <span>1 {{ 'sms.credit' | translate }}</span>
      </div>

      <mat-form-field>
        <mat-label>{{ 'user_attributes.email' | translate }}</mat-label>
        <input matInput type="email" formControlName="email" />
      </mat-form-field>

      <div class="submit-button-wrapper">
        <app-button size="small" [disabled]="resendInvitationForm.invalid" (click)="resendInvitation()">
          {{ 'user_profile.resend_invitation_button' | translate }}
        </app-button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #titlesTab>
  <div
    @contentAnimation
    [@.disabled]="isSmallScreen"
    class="content-container content-container--titles"
    appScrollShadow
  >
    <div @contentAnimation *ngIf="userTitles" class="container">
      <ul class="titles">
        <!-- TODO: update track, since title.id are the same in different groups -->
        @for (title of userTitles; track title.id) {
          <li @titleAnimation class="titles__item-wrapper">
            <div class="titles__item">
              <p>
                <span class="ns-subtitle small">{{ title.title }}</span>
                <span class="ns-text small"> {{ 'user_profile.in' | translate }} {{ title.group }} </span>
              </p>
              <app-button
                theme="transparent"
                format="text"
                size="small"
                class="titles__remove-button"
                (click)="openRemoveTitleModal(title)"
              >
                {{ 'user_profile.remove' | translate }}
              </app-button>
            </div>
          </li>
        }
      </ul>

      <form class="ns-form ns-light">
        <mat-form-field>
          <mat-label>{{ 'user_profile.title' | translate }}</mat-label>
          <mat-select [formControl]="newTitleControl">
            <mat-option *ngFor="let title of allTitles" [value]="title">{{ title.title }}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>

      <div class="submit-button-wrapper">
        <app-button theme="dark" size="small" [disabled]="!newTitleControl.value" (click)="addTitle()">
          {{ 'user_profile.add' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #relationsTab>
  <div
    @contentAnimation
    [@.disabled]="isSmallScreen"
    class="content-container content-container--relations"
    appScrollShadow
  >
    <p class="sub-header ns-subtitle">{{ 'group.guardians' | translate }}</p>
    <div class="container">
      @if (user!.guardians.length) {
        <ul class="user-list">
          <li *ngFor="let guardian of user!.guardians">
            <button class="user-button" (click)="openUserProfile(guardian.id)">
              <app-profile-picture [user]="guardian"></app-profile-picture>
              <div class="user-button__text">
                <p class="user-button__name ns-subtitle small">{{ guardian.full_name }}</p>
                <p class="ns-text extra-small">{{ guardian.phone_mobile | phone }}</p>
                <p class="ns-text extra-small">{{ guardian.email }}</p>
              </div>
              <mat-icon class="user-button__icon">chevron_right</mat-icon>
            </button>
          </li>
        </ul>

        @if (user!.activation_status !== ActivationStatus.WaitingForMembership) {
          <div class="add-guardian-button-wrapper">
            <app-button size="small" (click)="openAddGuardianPopup()">
              {{ 'organization.add_guardian' | translate }}
            </app-button>
          </div>
        }
      } @else {
        <div class="no-guardians">
          <mat-icon>supervised_user_circle</mat-icon>
          <p class="ns-subtitle small">{{ 'user_profile.no_guardians' | translate }}</p>
          <p class="ns-text small">
            {{
              'user_profile.no_guardians_description' | translate: { name: user!.first_name + ' ' + user!.last_name }
            }}
          </p>
          @if (user!.activation_status !== ActivationStatus.WaitingForMembership) {
            <app-button size="small" (click)="openAddGuardianPopup()">
              {{ 'organization.add_guardian' | translate }}
            </app-button>
          }
        </div>
      }
    </div>

    @if (user!.children.length) {
      <div class="divider"></div>
      <div class="container">
        <p class="sub-header ns-subtitle">{{ 'user_profile.children' | translate }}</p>
        <ul class="user-list">
          <li *ngFor="let child of user!.children">
            <button class="user-button" (click)="openUserProfile(child.id)">
              <app-profile-picture [user]="child"></app-profile-picture>
              <div class="user-button__text">
                <p class="user-button__name ns-subtitle small">{{ child.full_name }}</p>
                <p class="ns-text extra-small">{{ child.phone_mobile | phone }}</p>
                <p class="ns-text extra-small">{{ child.email }}</p>
              </div>
              <mat-icon class="user-button__icon">chevron_right</mat-icon>
            </button>
          </li>
        </ul>
      </div>
    }
  </div>
</ng-template>

<ng-template #paymentsTab>
  <div @contentAnimation [@.disabled]="isSmallScreen" class="content-container content-container--payments">
    <div class="description ns-text small">
      <p>{{ 'user_profile.payments_tab_description_1' | translate }}</p>
      <p>
        {{ 'user_profile.payments_tab_description_2' | translate }}
        <app-button theme="transparent" format="text" size="small" (click)="goToIndividualPaymentsPage()">
          {{ 'user_profile.click_here' | translate }}
        </app-button>.
      </p>
    </div>

    <app-table
      [table]="paymentsTable"
      [data]="paymentsTable.data$ | async"
      [columns]="paymentsTable.columns"
      [rowsCount]="paymentsTable.rowsCount"
      [rowsTotal]="paymentsTable.rowsTotal"
      [loading]="paymentsTable.loading"
      [page]="paymentsTable.page"
      [search]="false"
      [emptyStateMessage]="'no_content.no_not_paid_payments' | translate"
      matSort
      (matSortChange)="onSort($event)"
    >
      <ng-container [matColumnDef]="Columns.PaymentName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table.payment_name' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="ns-scrollable-cell-wrapper">
            <p>{{ row.payment.name }}</p>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="Columns.Group">
        <mat-header-cell *matHeaderCellDef>{{ 'table.group' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="center">
          <div class="ns-scrollable-cell-wrapper">
            <p>{{ row.team || '-' }}</p>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="Columns.Type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table.type' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="center">
          <ng-container [ngSwitch]="row.type">
            <ng-container *ngSwitchCase="PaymentTypes.RegularPayment">
              {{ 'economy.other_payment' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="PaymentTypes.EventPayment">
              {{ 'economy.event_payment' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="PaymentTypes.MembershipFee">
              {{ 'economy.membership_fee' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="PaymentTypes.TrainingFee">
              {{ 'economy.training_fee' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="PaymentTypes.Dugnad">
              {{ 'economy.dugnad_payment' | translate }}
            </ng-container>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="Columns.NetAmount">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table.net_amount' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="center">
          {{ (row.net_amount | isNumber) ? (row.net_amount | getNOK | currency) : '-' }}
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="Columns.DueDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table.due_date' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="center">
          <span [class.ns-expired]="row.is_overdue">{{ row.due_date | date: DateFormat.shortDate }}</span>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="Columns.PaymentStatus">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table.payment_status' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="center">
          <div class="ns-payment-status-cell" [ngSwitch]="row.paid_status">
            <p *ngSwitchCase="PaidStatus.PaidManually" class="ns-paid">
              {{ 'payment.paid_manually' | translate }}
            </p>
            <p *ngSwitchCase="PaidStatus.Paid" class="ns-paid">{{ 'payment.paid' | translate }}</p>
            <p *ngSwitchCase="PaidStatus.Exempted">{{ 'payment.exempted' | translate }}</p>
            <p *ngSwitchCase="PaidStatus.Optional">{{ 'payment.optional' | translate }}</p>
            <p *ngSwitchCase="PaidStatus.Declined">{{ 'payment.declined' | translate }}</p>
            <p *ngSwitchCase="PaidStatus.NotPaid" class="ns-not-paid">{{ 'payment.not_paid' | translate }}</p>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="Columns.LastReminder">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table.last_reminder' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="center" [class.clickable]="row.latest_reminder_date">
          <button *ngIf="row.latest_reminder_date" class="ns-clickable-cell-button" (click)="openReminderHistory(row)">
            {{ row.latest_reminder_date | date: DateFormat.shortDate }}
          </button>

          <ng-container *ngIf="!row.latest_reminder_date">-</ng-container>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="Columns.LastInvoice">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table.last_invoice' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="center">
          {{ row.latest_invoice_date ? (row.latest_invoice_date | date: DateFormat.shortDate) : '-' }}
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="Columns.Comments">
        <mat-header-cell *matHeaderCellDef>{{ 'table.comments' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="ns-scrollable-cell-wrapper">
            <p>{{ row.note }}</p>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="Columns.Actions">
        <mat-header-cell *matHeaderCellDef class="sticky right"></mat-header-cell>
        <mat-cell *matCellDef="let row" class="sticky right clickable">
          <button
            *ngIf="
              (row.paid_status === PaidStatus.Paid ||
                row.paid_status === PaidStatus.PaidManually ||
                (row.type === PaymentTypes.MembershipFee && !userEconomyPermissions.membershipFee.canEdit) ||
                (row.type === PaymentTypes.TrainingFee && !userEconomyPermissions.trainingFee.canEdit)) &&
                !row.latest_reminder_date &&
                !row.latest_invoice_date;
              else menu
            "
            class="ns-kebab-menu-button"
            disabled
          >
            <mat-icon>more_vert</mat-icon>
          </button>

          <ng-template #menu>
            <button class="ns-kebab-menu-button" appBlurOnClick (click)="menuTrigger.openMenu()">
              <mat-icon #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="moreMenu">more_vert</mat-icon>
            </button>
          </ng-template>

          <mat-menu #moreMenu="matMenu">
            <button
              *ngIf="
                (row.type !== PaymentTypes.Dugnad && row.paid_status === PaidStatus.NotPaid) ||
                (row.type === PaymentTypes.Dugnad &&
                  ((row.order_status === OrderStatus.NotSet && row.paid_status !== PaidStatus.Exempted) ||
                    row.paid_status === PaidStatus.NotPaid))
              "
              mat-menu-item
              (click)="openNewReminder(row)"
            >
              {{ 'payment.send_reminder' | translate }}
            </button>
            <button *ngIf="row.latest_reminder_date" mat-menu-item (click)="openReminderHistory(row)">
              {{ 'payment.reminder_history' | translate }}
            </button>
            <button
              *ngIf="
                (row.type === PaymentTypes.RegularPayment ||
                  row.type === PaymentTypes.EventPayment ||
                  (row.type === PaymentTypes.MembershipFee && userEconomyPermissions.membershipFee.canEdit) ||
                  (row.type === PaymentTypes.TrainingFee && userEconomyPermissions.trainingFee.canEdit)) &&
                (row.paid_status === PaidStatus.NotPaid || row.paid_status === PaidStatus.Optional)
              "
              mat-menu-item
              (click)="openNewInvoice(row)"
            >
              {{ 'economy.generate_new_invoice' | translate }}
            </button>
            <button *ngIf="row.latest_invoice_date" mat-menu-item (click)="openInvoiceHistory(row)">
              {{ 'economy.invoice_history' | translate }}
            </button>
            <button
              *ngIf="
                (row.type === PaymentTypes.RegularPayment ||
                  row.type === PaymentTypes.EventPayment ||
                  (row.type === PaymentTypes.MembershipFee && userEconomyPermissions.membershipFee.canEdit) ||
                  (row.type === PaymentTypes.TrainingFee && userEconomyPermissions.trainingFee.canEdit)) &&
                !row.charge_made
              "
              mat-menu-item
              (click)="openEditUserPayment(row)"
            >
              {{ 'action.edit' | translate }}
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>
    </app-table>
  </div>
</ng-template>

<ng-template #chatTab>
  <div @contentAnimation [@.disabled]="isSmallScreen" class="content-container content-container--chat">
    <stream-channel>
      <stream-message-list></stream-message-list>
      <stream-message-input></stream-message-input>
    </stream-channel>
  </div>
</ng-template>

<app-popup
  *ngIf="addGuardianPopup.isOpen"
  [loading]="addGuardianPopup.loading"
  [isDismissible]="!addGuardianPopup.loading"
  class="add-guardian"
  (popupClose)="closeAddGuardianPopup()"
>
  <h3 class="popup__header">
    <span>{{ 'user_profile.add_existing_guardian' | translate }}</span>
    <div class="popup__header-button-wrapper">
      <div class="popup__header-buttons">
        <button mat-icon-button (click)="closeAddGuardianPopup()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </h3>

  <div class="popup__body">
    <ul class="ns-tabs ns-tabs--simple ns-tabs--align-center">
      <li class="ns-tab">
        <button
          class="ns-tab__button"
          [class.ns-tab__button--active]="isSearchingByName"
          appBlurOnClick
          (click)="setSearchingByName(true)"
        >
          {{ 'add_user.add_by_name' | translate }}
        </button>
      </li>
      <li class="ns-tab">
        <button
          class="ns-tab__button"
          [class.ns-tab__button--active]="!isSearchingByName"
          appBlurOnClick
          (click)="setSearchingByName(false)"
        >
          {{ 'add_user.add_by_email' | translate }}
        </button>
      </li>
    </ul>

    <form class="ns-form ns-light" autocomplete="off">
      <app-expand-y *ngIf="isSearchingByName" class="ns-expandable-field-wrapper">
        <mat-form-field class="ns-expandable-field ns-no-gap">
          <mat-label>{{ 'add_user.enter_name' | translate }}</mat-label>
          <input matInput [formControl]="searchByNameControl" />
        </mat-form-field>
      </app-expand-y>

      <app-expand-y *ngIf="!isSearchingByName" class="ns-expandable-field-wrapper">
        <mat-form-field class="ns-expandable-field ns-no-gap">
          <mat-label>{{ 'add_user.enter_email' | translate }}</mat-label>
          <input matInput [formControl]="searchByEmailControl" />
        </mat-form-field>
      </app-expand-y>
    </form>

    <app-expand-y
      *ngIf="
        userList.length ||
        addGuardianSearchLoading ||
        (isSearchingByName && noUserFoundByName) ||
        (!isSearchingByName && noUserFoundByEmail)
      "
      class="ns-search-results-expand"
    >
      <ng-container [ngTemplateOutlet]="searchResultsTemplate"></ng-container>
    </app-expand-y>
  </div>

  <div class="popup__button-wrapper">
    <app-button
      size="small"
      [disabled]="
        addGuardianSearchLoading ||
        (isSearchingByName && !selectedUserByName) ||
        (!isSearchingByName && !selectedUserByEmail)
      "
      (click)="addGuardian()"
    >
      {{ 'user_profile.add_guardian' | translate }}
    </app-button>
  </div>
</app-popup>

<ng-template #searchResultsTemplate>
  <div class="ns-search-results-wrapper" [appScrollShadow]="['top', 'bottom']">
    <app-loader *ngIf="addGuardianSearchLoading" [size]="50"></app-loader>

    <div *ngIf="isSearchingByName && noUserFoundByName" class="ns-empty-state">
      <p class="ns-subtitle">{{ 'user_profile.no_user_by_name' | translate }}</p>
      <p class="ns-text small">{{ 'user_profile.try_another_name' | translate }}</p>
    </div>

    <div *ngIf="!isSearchingByName && noUserFoundByEmail" class="ns-empty-state">
      <p class="ns-subtitle">{{ 'user_profile.no_user_by_email' | translate }}</p>
      <p class="ns-text small">{{ 'user_profile.try_another_email' | translate }}</p>
    </div>

    <ul *ngIf="userList.length" class="ns-search-results ns-list">
      <li
        *ngFor="let user of userList"
        class="ns-list__item"
        [class.ns-list__item--selected]="
          (isSearchingByName && user.id === selectedUserByName?.id) ||
          (!isSearchingByName && user.id === selectedUserByEmail?.id)
        "
      >
        <button
          class="ns-list__button ns-list__button--no-shadow"
          type="button"
          appBlurOnClick
          (click)="selectUser(user)"
        >
          <div class="ns-avatar-wrapper">
            <app-profile-picture [user]="user"></app-profile-picture>
            <app-user-activation-status
              [status]="user.activation_status"
              [supervised]="user.supervised_by_parents"
            ></app-user-activation-status>
          </div>
          <div class="ns-user-details">
            <p class="ns-name ns-subtitle small">{{ user.first_name }} {{ user.last_name }}</p>
            <p *ngIf="user.email" class="ns-text extra-small">
              {{ 'user_attributes.email' | translate }}: {{ user.email }}
            </p>
            <p *ngIf="$any(user).groups?.length" class="ns-text extra-small">
              <span *ngFor="let group of $any(user).groups; let last = last"
                >{{ group.name }}<span *ngIf="!last">, </span>
              </span>
            </p>
          </div>
        </button>
      </li>
    </ul>
  </div>
</ng-template>

<app-popup
  *ngIf="jerseyNumberPopup.isOpen"
  [loading]="jerseyNumberPopup.loading"
  [isDismissible]="!jerseyNumberPopup.loading"
  (popupClose)="jerseyNumberPopup.close()"
>
  <h3 class="popup__header">
    <span>{{ 'user_profile.edit_jersey_number' | translate }}</span>
    <div class="popup__header-button-wrapper">
      <div class="popup__header-buttons">
        <button mat-icon-button (click)="jerseyNumberPopup.close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </h3>
  <div class="popup__body default-text-color ns-form ns-light">
    <p class="jersey-popup-group-description ns-text ns-small">
      {{ 'group.' | translate }}: {{ selectedJerseyNumberGroup.name }}
      <ng-container *ngIf="selectedJerseyNumberGroup.divisionName">
        • {{ selectedJerseyNumberGroup.divisionName }}
      </ng-container>
    </p>
    <mat-form-field class="ns-no-gap">
      <mat-label>{{ 'user_attributes.jersey_number' | translate }}</mat-label>
      <input matInput type="number" [min]="0" [(ngModel)]="selectedJerseyNumberGroup.number" />
    </mat-form-field>
  </div>
  <div class="popup__button-wrapper">
    <app-button size="small" (click)="updateJerseyNumber()">{{ 'action.save' | translate }}</app-button>
  </div>
</app-popup>

<app-modal
  *ngIf="removeTitleModal.isOpen"
  [loading]="removeTitleModal.loading"
  [isDismissible]="!removeTitleModal.loading"
  (modalClose)="closeRemoveTitleModal()"
>
  <button class="modal__close-button" mat-icon-button (click)="closeRemoveTitleModal()">
    <mat-icon>close</mat-icon>
  </button>
  <h3 class="modal__header">{{ 'user_profile.remove_title' | translate }}</h3>
  <div class="modal__body default-text-color ns-text">
    {{
      'user_profile.remove_title_description'
        | translate
          : {
              title: titleToRemove!.title,
              groupName: titleToRemove!.group,
              userName: this.user!.first_name + ' ' + this.user!.last_name
            }
    }}
  </div>
  <div class="modal__button-wrapper">
    <app-button theme="transparent" size="small" (click)="closeRemoveTitleModal()">
      {{ 'action.cancel' | translate }}
    </app-button>
    <app-button size="small" (click)="removeTitle()">
      {{ 'user_profile.remove_title' | translate }}
    </app-button>
  </div>
</app-modal>

<app-new-reminder
  *ngIf="isNewReminderOpen && selectedPayment && selectedPayment.type !== PaymentTypes.Dugnad"
  [user]="selectedPayment.user"
  [userPaymentId]="selectedPayment.id"
  (close)="closeNewReminder($event)"
></app-new-reminder>

<app-new-dugnad-reminder
  *ngIf="isNewReminderOpen && selectedPayment && selectedPayment.type === PaymentTypes.Dugnad"
  name="{{ selectedPayment.user.first_name }} {{ selectedPayment.user.last_name }}"
  [dugnadType]="selectedPayment.dugnad_info.dugnad_type"
  [orderId]="selectedPayment.dugnad_info.order_id"
  [isConfirmed]="selectedPayment.dugnad_info.is_confirmed"
  [orderStatus]="selectedPayment.dugnad_info.order_status"
  [paidStatus]="selectedPayment.paid_status"
  (close)="closeNewReminder($event)"
></app-new-dugnad-reminder>

<app-reminder-history
  *ngIf="isReminderHistoryOpen && selectedPayment && selectedPayment.type !== PaymentTypes.Dugnad"
  [user]="selectedPayment.user"
  [paymentName]="selectedPayment.payment.name"
  [userPaymentId]="selectedPayment.id"
  [paidStatus]="selectedPayment.paid_status"
  (close)="closeReminderHistory($event)"
></app-reminder-history>

<app-dugnad-reminder-history
  *ngIf="isReminderHistoryOpen && selectedPayment && selectedPayment.type === PaymentTypes.Dugnad"
  userName="{{ selectedPayment.user.first_name }} {{ selectedPayment.user.last_name }}"
  [dugnadName]="selectedPayment.payment.name"
  [orderId]="selectedPayment.dugnad_info.order_id"
  [isConfirmed]="selectedPayment.dugnad_info.is_confirmed"
  [orderStatus]="selectedPayment.dugnad_info.order_status"
  [paidStatus]="selectedPayment.paid_status"
  (close)="closeReminderHistory($event)"
></app-dugnad-reminder-history>

<app-new-invoice
  *ngIf="isNewInvoiceOpen && selectedPayment"
  [user]="selectedPayment.user"
  [paymentName]="selectedPayment.payment.name"
  [userPaymentId]="selectedPayment.id"
  [paymentType]="selectedPayment.type"
  [dueDate]="selectedPayment.due_date"
  [netAmount]="selectedPayment.net_amount"
  [note]="selectedPayment.note"
  (close)="closeNewInvoice($event)"
></app-new-invoice>

<app-invoice-history
  *ngIf="isInvoiceHistoryOpen && selectedPayment"
  [user]="selectedPayment.user"
  [paymentName]="selectedPayment.payment.name"
  [userPaymentId]="selectedPayment.id"
  [paymentType]="selectedPayment.type"
  [paidStatus]="selectedPayment.paid_status"
  (close)="closeInvoiceHistory($event)"
></app-invoice-history>

<app-edit-user-payment
  *ngIf="isEditUserPaymentOpen && selectedPayment && selectedPayment.type !== PaymentTypes.Dugnad"
  [user]="selectedPayment.user"
  [userPaymentId]="selectedPayment.id"
  [paymentName]="selectedPayment.payment.name"
  [dueDate]="selectedPayment.due_date"
  [amount]="selectedPayment.amount"
  [includeTransactionFee]="selectedPayment.include_fee"
  [initialValues]="{
    netAmount: selectedPayment.net_amount,
    transactionFee: selectedPayment.total_fee,
    totalAmount: selectedPayment.gross_amount
  }"
  [paidStatus]="selectedPayment.paid_status"
  [paymentType]="selectedPayment.type"
  [note]="selectedPayment.note"
  [isOptional]="selectedPayment.is_optional"
  (close)="closeEditUserPayment($event)"
></app-edit-user-payment>

<app-edit-dugnad-order
  *ngIf="isEditUserPaymentOpen && selectedPayment && selectedPayment.type === PaymentTypes.Dugnad"
  [id]="selectedPayment.dugnad_info.order_id"
  (close)="closeEditUserPayment($event)"
></app-edit-dugnad-order>
